import { ENV } from "runenv";
import { Block, WarningAlt } from "assets/icons/internal";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import { SvgIconComponent } from "@mui/icons-material";
import { riskydashboard, types } from "api";
import {
  types_SideSelectorEnum,
  types_IncidentActionEnum,
  types_SelectionTypeEnum,
  aggs,
} from "api/gen";
import { AllCategories, AllDatasets } from "modules/risks/shared";
import { Sensitivity } from "ui/types";
import { UserFilter } from "modules/page-navigation";
import { CELConditionOperator, CELOperators, celCombine } from "libs/cel-query";
import { DateRange } from "./types";
import { NULL_SEVERITY, Severity, isAllSeveritiesSelected } from "../dashboard-core/severity";
import {
  NULL_SENSITIVITY,
  isAllSensitivitiesSelected,
  sensitivityList,
} from "../dashboard-core/sensitivity";
import { LINEA_AI_DETECTION_SKU } from "../alerts-table/linea-features";

export type EdgeView_Extended = riskydashboard.EdgeViewRequest & {
  status: types.CategoryStatus;

  timezone: string;
  selected_severities?: Severity[] | null;
  selected_sensitivities?: Sensitivity[] | null;
};

function omitEmptyArray<T>(items: T[]) {
  const noItems = items?.length === 0;
  return noItems ? null : items;
}

function createDatasetFilter(
  items: string[],
  previewDataset: riskydashboard.DatasetDTO,
  hideAppliedTo: boolean
): riskydashboard.DatasetsFilter {
  const all = items?.includes(AllDatasets);
  const res = {
    all_datasets: all,
    datasets: all ? null : omitEmptyArray(items),
  };
  if (previewDataset) {
    return {
      ...res,
      all_datasets: false,
      preview_dataset: {
        ...previewDataset,
        category_ids: hideAppliedTo ? [] : previewDataset.category_ids,
      },
    };
  }
  return res;
}

export function applyEventIdsToGlobalFilter(globalFilter: string, eventIds: string[]) {
  if (eventIds?.length > 0) {
    return celCombine(
      CELConditionOperator.AND,
      globalFilter,
      CELOperators.common.in("e.destination.raw_id", eventIds.map(CELOperators.toString))
    );
  }
  return globalFilter;
}

export type LocationFiltersConfig = {
  selectedLocationsDestinationsGlobal: Set<types.ExpandableLocationItem>;
  selectedLocationsSourcesGlobal: Set<types.ExpandableLocationItem>;
  selectedLocationsGlobal: Set<types.ExpandableLocationItem>;
};

export function applyLocationToGlobalFilter(
  globalFilterInput: string,
  {
    selectedLocationsDestinationsGlobal,
    selectedLocationsGlobal,
    selectedLocationsSourcesGlobal,
  }: LocationFiltersConfig
) {
  const sourceMatchFilter = (locations: Set<types.ExpandableLocationItem>) => {
    const locationMatchers = [...locations].map(({ location_outline }) =>
      CELOperators.common.equal("e.source.location_outline", `"${location_outline}"`)
    );
    return celCombine(CELConditionOperator.OR, ...locationMatchers);
  };

  const destinationMatchFilter = (locations: Set<types.ExpandableLocationItem>) => {
    const locationMatchers = [...locations].map(({ location_outline }) =>
      CELOperators.common.equal("e.destination.location_outline", `"${location_outline}"`)
    );
    return celCombine(CELConditionOperator.OR, ...locationMatchers);
  };

  let globalFilter = globalFilterInput;
  if (selectedLocationsGlobal.size > 0) {
    const locationCelPortion = celCombine(
      CELConditionOperator.OR,
      sourceMatchFilter(selectedLocationsGlobal),
      destinationMatchFilter(selectedLocationsGlobal)
    );

    globalFilter = celCombine(CELConditionOperator.AND, globalFilter, locationCelPortion);
  }

  if (selectedLocationsDestinationsGlobal.size > 0) {
    globalFilter = celCombine(
      CELConditionOperator.AND,
      globalFilter,
      destinationMatchFilter(selectedLocationsDestinationsGlobal)
    );
  }

  if (selectedLocationsSourcesGlobal.size > 0) {
    globalFilter = celCombine(
      CELConditionOperator.AND,
      globalFilter,
      sourceMatchFilter(selectedLocationsSourcesGlobal)
    );
  }

  return globalFilter;
}

function createCategoriesFilter(
  items: string[],
  previewCategory: types.Category,
  hideAppliedTo: boolean,
  addUncategorized: boolean = false
): riskydashboard.CategoriesFilter {
  const all = items?.includes(AllCategories);
  const itemsCopy = [...items];

  if (addUncategorized && !itemsCopy.includes("Uncategorized")) {
    itemsCopy.push("Uncategorized");
  }
  const res = {
    all_categories: all,
    categories: all ? null : omitEmptyArray(itemsCopy),
  };

  if (previewCategory) {
    return {
      ...res,
      all_categories: false,
      preview_category: {
        category: {
          ...previewCategory,
          dataset_ids: hideAppliedTo ? undefined : previewCategory.dataset_ids,
        },
      },
    };
  }

  return res;
}

function createQuerySideLocation(
  locations: Iterable<types.LocationFilterItem>,
  side_selector: types.SideSelector
) {
  const locationsArray = Array.from(locations);
  if (locationsArray.length === 0) {
    return null;
  }
  return {
    side_selector: side_selector,
    location_filters: omitEmptyArray(
      locationsArray.map((el) => ({
        location_path: (el as any).expand_path,
      }))
    ),
  };
}
export function toQuery({
  globalFilter,
  timeFilter,
  selectedUsers,
  selectedDirectoryUsers,
  selectedLocations,
  selectedCategories,
  selectedDatasets,
  previewDataset,
  previewCategory,
  isBothSearch,
  isAppliedToCategoriesAreHidden,
  selectedLocationsDestinations,
  selectedLocationsSources,
  selectedSeverities,
  selectedSensitivities,
  statuses,
  isAllPoliciesSelected,
  isAllSeveritiesSelected,
  selectedHostnames,
}: any): EdgeView_Extended {
  const users = Array.from(selectedUsers.values()) as any;
  const locations = Array.from(selectedLocations.values()) as any;

  const datasets_filter = createDatasetFilter(selectedDatasets, previewDataset, isBothSearch);
  // "previewDataset && !previewDataset?.id" means that we want to include uncategorized when we
  // search by dataset but not with default filters or when we are editing a dataset.
  const addUncategorized = isAllPoliciesSelected && previewDataset && !previewDataset?.id;
  const categories_filter = createCategoriesFilter(
    selectedCategories,
    previewCategory,
    isBothSearch || isAppliedToCategoriesAreHidden,
    addUncategorized
  );
  let severitiesFilter = selectedSeverities;

  if (categories_filter.categories?.includes("Uncategorized") && isAllSeveritiesSelected) {
    severitiesFilter = null;
  }
  return {
    categories_filter,
    datasets_filter,
    locations_filters: [
      createQuerySideLocation(locations, types_SideSelectorEnum.SideSelectorBoth),
      createQuerySideLocation(selectedLocationsSources, types_SideSelectorEnum.SideSelectorDataset),
      createQuerySideLocation(
        selectedLocationsDestinations,
        types_SideSelectorEnum.SideSelectorCategory
      ),
    ].filter(Boolean) as riskydashboard.TotalCountRequest["locations_filters"],
    users_filter: {
      users: users.map((u: any) => u.user),
      directory_users: selectedDirectoryUsers.map((u: UserFilter) => ({ user_id: u.value })),
    },
    times_filter: {
      ...timeFilter,
    },
    statuses,
    selected_severities: severitiesFilter,
    hostnames_filter: [...selectedHostnames],
    global_filter: globalFilter,
    selected_sensitivities: selectedSensitivities,
  } as any;
}

export function categoryToId(d: any) {
  return d.id + d.status;
}

export const isAlertEnabled = (rule: types.Category["rule"]) => !!rule?.create_incident;
export const aiIncidentsEnabled = (rule: types.Category["rule"]) => {
  return (
    !!ENV.FEATURES.AI_INCIDENT_DETECTION &&
    LINEA_AI_DETECTION_SKU &&
    rule && // ! Important so we don't return `true` for unmatched
    !rule.create_incident &&
    !rule.disable_ai_incidents
  );
};

const checkCategoryField = (
  category: types.Category,
  datasetId: string,
  checkFn: (r: types.Category["rule"]) => boolean
) => {
  if (datasetId !== AllDatasets && category.id) {
    return category?.dataset_ids?.includes(datasetId) && checkFn(category.rule);
  } else {
    return checkFn(category.rule);
  }
};

export function getFlagsForCategory(category: types.Category, dataset: string) {
  return {
    notify_email: checkCategoryField(
      category,
      dataset,
      (rule) => (isAlertEnabled(rule) || aiIncidentsEnabled(rule)) && !!rule?.notify_enabled
    ),
    create_incident: checkCategoryField(category, dataset, isAlertEnabled),
    response_enabled: checkCategoryField(category, dataset, (rule) => {
      return isAlertEnabled(rule) && !!rule?.incident_action;
    }),
    aiIncidentsEnabled: checkCategoryField(category, dataset, aiIncidentsEnabled),
  };
}

export const ensurePolicyDatasetsOrder = (policy: types.Category) => {
  return {
    ...policy,
    dataset_ids: policy?.dataset_ids ? [...policy.dataset_ids].sort() : [],
  };
};

export const datasetsFilterToDatasetFilterV2 = (
  filters?: riskydashboard.DatasetsFilter | null
): types.DatasetsFilter | null => {
  if (!filters) {
    return null;
  }
  if (!filters.datasets?.length) {
    return null;
  }

  return {
    all_datasets: filters.all_datasets,
    dataset_ids: filters.datasets,
  };
};

export const catergoriesFilterToPolicyFilterV2 = (
  filters?: riskydashboard.CategoriesFilter | null
): types.PoliciesFilter | null => {
  if (!filters) {
    return null;
  }
  if (!filters.categories?.length) {
    return null;
  }

  return {
    all_policies: filters.all_categories,
    policy_ids: filters.categories,
  };
};

export const selectedSeveritiesToFilter = (
  severities?: Severity[] | null,
  uncategorizedSelected: boolean = false
): types.SeverityFilter | null => {
  // If no or all severities are selected, it's all the same - we're expecting to
  // get events of severities, so just skip the filters.
  if (!severities?.length || isAllSeveritiesSelected(severities)) {
    return null;
  }

  return {
    category_severities: severities.filter((s) => s != (NULL_SEVERITY as Severity)),
    allow_null_severity: uncategorizedSelected,
  };
};

export const selectedSensitivitiesToFilter = ({
  sensitivities,
  isUncategorizedIncluded,
}: {
  sensitivities?: Sensitivity[] | null;
  isUncategorizedIncluded?: boolean;
}): types.SensitivityFilter | null => {
  if (!sensitivities) {
    return null;
  }
  const isAllSelected = isAllSensitivitiesSelected(sensitivities);
  if (isAllSelected && isUncategorizedIncluded) {
    return null;
  }
  return {
    dataset_sensitivities: sensitivities.filter((s) => s != (NULL_SENSITIVITY as Sensitivity)),
    allow_null_sensivitity: false,
    // NOTE: uncomment this when null severity support will be added
    // allow_null_sensivitity: isNullSensitivityAllowed(sensitivities) || !!isUncategorizedIncluded,
  };
};

export const previewPolicyToPreviewPolicyV2 = (
  policy?: null | riskydashboard.PreviewCategory,
  datasetIds: string[] = []
): { policy: aggs.PreviewPolicy } | null => {
  if (!policy) {
    return null;
  }

  return { policy: processPreviewPolicy(policy.category, datasetIds) };
};

export const processPreviewPolicy = (policy: types.Category, datasetIds: string[]) => {
  const policyData = { ...policy };
  if (
    policyData.selection_type === types_SelectionTypeEnum.SelectionTypeSensitivity &&
    !policyData.dataset_sensitivities?.length
  ) {
    policyData.dataset_sensitivities = sensitivityList.map((s) => s.sensitivity);
  }

  if (
    policyData.selection_type === types_SelectionTypeEnum.SelectionTypeDataset &&
    !policyData.dataset_ids?.length
  ) {
    policyData.dataset_ids = datasetIds;
  }

  return policyData;
};

export const previewDatasetToPreviewDatasetV2 = (
  dataset?: null | riskydashboard.DatasetDTO
): { dataset: types.Dataset; applied_policies: Array<types.Category | null> | null } | null => {
  if (!dataset) {
    return null;
  }

  return {
    dataset,
    applied_policies: [],
  };
};
export function isPolicyScreenshotsDisabled(policy: types.Category) {
  return (
    !ENV.FEATURES.ENABLE_SCREENSHOTS ||
    !policy?.rule?.create_incident ||
    (policy?.rule?.incident_action === types_IncidentActionEnum.IncidentActionNone &&
      !ENV.FEATURES.ENABLE_SILENT_SCREENSHOTS)
  );
}

export type IncidentAction = "warn" | "block" | "none";

export function getPolicyIcon(
  incidentAction?: IncidentAction | null
): SvgIconComponent | undefined {
  switch (incidentAction) {
    case "block":
      return Block;
    case "warn":
      return WarningAlt;
  }

  return undefined;
}

export function getRanges(start: Date, end: Date): DateRange[] {
  const res = differenceInCalendarDays(end, start);
  const types: DateRange[] = [];
  if (res <= 1) {
    types.push("hourly");
  }
  if (res > 1 && res < 90) {
    types.push("daily");
  }
  if (res > 13) {
    types.push("weekly");
  }
  if (res > 60) {
    types.push("monthly");
  }

  return types;
}

export function getTimeRangeMatchingEdges(edges: Array<types.Edge | null>): types.TimesFilter {
  const filtered = edges.filter((e) => !!e) as types.Edge[];
  const start = new Date(
    Math.min(...filtered.map((e) => new Date(e.destination.local_time!).getTime()))
  );
  const end = new Date(
    Math.max(...filtered.map((e) => new Date(e.destination.local_time!).getTime()))
  );
  return {
    start_time: start.toISOString(),
    end_time: end.toISOString(),
  };
}
