import { get } from "lodash";

export type FilterConfig = Record<
  string,
  {
    queryKey: string;
    defaultValue: any;
    memoryFilterProps: InMemoryFilterProps;
  }
>;

type InMemoryFilterProps = {
  filterType: InMemoryFilterType;
  getter: ValueGetter<any>;
};

export enum InMemoryFilterType {
  // Returns entries that are equal to the filter value
  Inclusion = "inclusion",

  // Used with multiple filter values. Returnes entries that are
  // equal to any of the filter values.
  UnionInclusion = "union-inclusion",

  // Used with date values. Returns entries that are within the
  // date range defined by the filter
  DateRange = "date-range",

  // Returns entries that contain the filter value as a substring
  String = "string",
}

/**
 * Type that describes either a JS object key or a path to a nested key separated by dots
 */
type ValueGetter<V> = keyof V | ((v: V) => any);

/**
 * Builds a function that filters an array of objects based on the in memory
 * filter configuration.
 *
 * @param config The filter config to buld the filter function from
 * @returns The filter function
 */
export function getMemoryFilterFunction<V>(
  config: FilterConfig
): (data: V[], filterValues: any) => V[] {
  return (data: V[], filterValues: any): V[] => {
    if (!data) {
      return [];
    }

    return data.filter((value) =>
      Object.entries(config).every(([key, { memoryFilterProps }]) => {
        const { filterType, getter } = memoryFilterProps;
        const filterValue = filterValues[key];
        const filterFunctionGetter = filterFunctions[filterType];
        const filterFunction = filterFunctionGetter(getter, filterValue);

        return filterFunction(value);
      })
    );
  };
}

const filterFunctions = {
  [InMemoryFilterType.Inclusion]: getInclusionFilter,
  [InMemoryFilterType.UnionInclusion]: getUnionInclusionFilter,
  [InMemoryFilterType.DateRange]: getDateInclusionFilter,
  [InMemoryFilterType.String]: getStringInclusionFilter,
};

function getInclusionFilter<V>(key: ValueGetter<V>, values: any[]) {
  return (value: V) => !values?.length || values.includes(getValue(value, key));
}

function getUnionInclusionFilter<V>(key: ValueGetter<V>, values: any[]) {
  return (value: V) => {
    const arrayValue = getValue(value, key) as unknown[];
    return !values?.length || arrayValue.some((v) => values.includes(v));
  };
}

function getDateInclusionFilter<V>(key: ValueGetter<V>, range: [string, string]) {
  return (value: V) => {
    if (!range || !range[0] || !range[1]) {
      return true;
    }

    const currentDate = new Date(getValue(value, key));
    const startDate = new Date(range[0]);
    const endDate = new Date(range[1]);
    return currentDate >= startDate && currentDate <= endDate;
  };
}

function getStringInclusionFilter<V>(key: ValueGetter<V>, search: string) {
  return (value: V) => !search || getValue(value, key).toLowerCase().includes(search.toLowerCase());
}

function getValue<V>(v: V, getter: ValueGetter<V>) {
  return typeof getter === "function" ? getter(v) : get(v, getter);
}
