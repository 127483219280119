import constate from "constate";
import { useSearchParams } from "react-router-dom";
import { types } from "api";
import { useFilters as useFiltersState } from "modules/table";
import { RelativeDate } from "libs/time";
import { IncidentUserActionEnum } from "modules/360/types";
import { UserFilter } from "modules/page-navigation";
import { SensorType } from "../shared/constants";

const filters = {
  assignees: {
    queryKey: "alerts.assignees",
    defaultValue: new Set([] as string[]),
  },
  dateFilters: {
    queryKey: "alerts.time-filter",
    defaultValue: {} as types.TimesFilter & { relative?: RelativeDate },
  },
  selectedCategories: {
    queryKey: "alerts.selectedCategories",
    defaultValue: new Set<string>(),
  },
  selectedDatasets: {
    queryKey: "alerts.datasets",
    defaultValue: new Set<string>(),
  },
  selectedDirectoryUsers: {
    queryKey: "alerts.selectedDirectoryUsers",
    defaultValue: [] as UserFilter[],
  },
  selectedUsers: {
    queryKey: "alerts.selectedUsers",
    defaultValue: new Set<string>(),
  },
  selectedStatus: {
    queryKey: "alerts.selectedStatus",
    defaultValue: ["unresolved"] as types.ResolutionStatus[],
  },
  severities: {
    queryKey: "alerts.severities",
    defaultValue: new Set<types.CategoryStatus>(),
  },
  selectedFiles: {
    queryKey: "alerts.selectedFiles",
    defaultValue: new Set<string>(),
  },
  quickFilter: {
    queryKey: "alerts.quickFilter",
    defaultValue: undefined as unknown as IncidentQuickFilter,
  },
  selectedEmailIncidents: {
    queryKey: "incident_ids",
    defaultValue: [] as string[],
  },
  categorySeverities: {
    queryKey: "alerts.categorySeverities",
    defaultValue: new Set<number>(),
  },
  datasetSensitivities: {
    queryKey: "alerts.datasetSensitivities",
    defaultValue: new Set<number>(),
  },
  selectedSensorTypes: {
    queryKey: "alerts.selectedSensorTypes",
    defaultValue: new Set<SensorType>(),
  },
  selectedHostnames: {
    queryKey: "alerts.selectedHostnames",
    defaultValue: new Set<string>(),
  },
  aiSeverities: {
    queryKey: "alerts.aiSeverities",
    defaultValue: new Set<number>(),
  },
  closeReasons: {
    queryKey: "alerts.closeReasons",
    defaultValue: new Set<string>(),
  },
  incidentTypes: {
    queryKey: "alerts.incidentTypes",
    defaultValue: new Set<string>(),
  },
  lineaShadowIncidentsMode: {
    queryKey: "alerts.lineaShadowIncidentsMode",
    defaultValue: false,
  },
  incidentActions: {
    queryKey: "alerts.incidentActions",
    defaultValue: undefined as unknown as types.IncidentAction[],
  },
  userActions: {
    queryKey: "alerts.userActions",
    defaultValue: [] as unknown as IncidentUserActionEnum[],
  },
};

export function useFiltersInternal2() {
  const [search] = useSearchParams();
  const state = useFiltersState({
    filters: filters,
    initialValue: {
      selectedEmailIncidents: search.getAll("id"),
    },
    clearAllFilter: (name: string) => {
      return name !== "dateFilters" && name !== "lineaShadowIncidentsMode";
    },
  });

  const setQuickFilter = (quickFilter: IncidentQuickFilter | undefined) => {
    // Set the incidentTypes filter to reflect the quick filter selection
    if (quickFilter?.type === "incident_type") {
      state.setIncidentTypes(new Set([quickFilter.value as types.IncidentType]));
    } else if (state.quickFilter?.type === "incident_type") {
      // Clear the incident types if the user manually selects a different quick filter,
      // but only if the incident types were set by the quick filter
      state.setIncidentTypes(undefined);
    }

    if (quickFilter?.type !== "incident_type") {
    }

    state.setQuickFilter(quickFilter);
  };

  const setIncidentTypes = (incidentTypes: Set<types.IncidentType>) => {
    if (state.quickFilter?.type === "incident_type") {
      // Clear the quick filter if the user manually selects incident types
      state.setQuickFilter(undefined);
    }

    state.setIncidentTypes(incidentTypes);
  };

  const startDate = new Date(state.dateFilters.start_time!);
  const endDate = new Date(state.dateFilters.end_time!);
  return {
    startDate,
    endDate,
    ...state,
    setQuickFilter,
    setIncidentTypes,
    selectedResponse: state.quickFilter?.type === "response" ? state.quickFilter.value : null,
    selectedReaction: state.quickFilter?.type === "reaction" ? state.quickFilter.value : null,
    incidentTypes: [...state.incidentTypes],
    selectedAiMismatch: state.quickFilter?.type === "ai_mismatch" ? state.quickFilter.value : null,
  };
}

export type FiltersType = ReturnType<typeof useFilters>;

export const [FiltersProvider, useFilters] = constate(useFiltersInternal2);

export type IncidentQuickFilterKey = "reaction" | "response" | "incident_type" | "ai_mismatch";

type AiMismatchValue = boolean;

export type IncidentQuickFilterValue =
  | types.IncidentReaction
  | types.IncidentResponse
  | types.IncidentType
  | AiMismatchValue;

export type IncidentQuickFilter = {
  type: IncidentQuickFilterKey;
  value: IncidentQuickFilterValue;
};
