import { ENV } from "runenv";
import { useIsCyberhavenUser } from "shared/auth-hooks";
import { useFilters } from "./state";

export function useIsLineaIncidentsEnabled() {
  const isCyberhavenUser = useIsCyberhavenUser();
  const { lineaShadowIncidentsMode } = useFilters();
  return (
    ENV.FEATURES.AI_INCIDENT_DETECTION ||
    (ENV.FEATURES.LINEA_SHADOW_MODE !== "disabled" && isCyberhavenUser && lineaShadowIncidentsMode)
  );
}

export function useIsLineaSummariesEnabled() {
  return useIsLineaIncidentsEnabled() || ENV.FEATURES.LINEA_SHADOW_MODE === "partial";
}

export function useIsLineaEventsSummariesEnabled() {
  return useIsLineaSummariesEnabled() && ENV.FEATURES.EVENTS_AI_SUMMARY;
}

const aiDetectionSkuFlowTypes = ["linea_and_policy_incident_candidate", "linea_incident_candidate"];
export const LINEA_AI_DETECTION_SKU = aiDetectionSkuFlowTypes.every((flowType) =>
  ENV.FEATURES.FLOW_TYPES_SENT_TO_AI?.includes(flowType)
);
