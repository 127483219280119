/* eslint-disable */
// prettier-disable
// prettier-ignore
// Code generated by api2. DO NOT EDIT.
import axios, { Method } from "axios"
import { ResponseMapping, RequestMapping, Route, RouteRequestOptions } from "libs/api";
import { QueryClient, QueryKey } from "@tanstack/react-query";

export function route<Req, Res>(
  method: Method,
  url: string,
  // @ts-ignore
  requestMapping: RequestMapping,
  // @ts-ignore
  responseMapping: ResponseMapping
): Route<Req, Res> {
  return Object.assign(
    (data: Req | null, options: RouteRequestOptions = {}) => {
      let params: Req | null = null;

      if (method === "GET") {
        params = data;
        data = null;
      }

      return axios
        .request<Res>({
          method,
          url,
          data,
          params,
          ...options,
        })
        .then((el) => el.data);
    },
    {
      method,
      url,
    }
  );
}

export function invalidateMultipleQueries(queryClient: QueryClient, keys: QueryKey[]) {
  return Promise.all(keys.map((key) => queryClient.invalidateQueries({ queryKey: key })));
}
