import React from "react";
import { Text } from "./Text";

interface TopBarProps {
  title: string;
  rightContent?: React.ReactNode;
}
export function TopBar({ title, rightContent }: TopBarProps) {
  return (
    <div className="p-4xl flex justify-between items-center bg-background-base3 border-0 border-b border-solid border-outlines-1 shadow-sm">
      <Text as="h1" color="primary" variant="XLLRegular" className="relative m-0">
        {title}
        <span
          className="absolute rounded-s bg-text-primary"
          style={{ width: "var(--spacing-4xl)", height: "var(--spacing-xs)", left: 0, bottom: 0 }}
        />
      </Text>

      {rightContent}
    </div>
  );
}
