import { useUpdateUserPreferences, useUserPreferences } from "./user-preferences/userPreferences";
import { DynamicBackfillsDefault } from "api/gen";

export function useDynamicBackfillsSettings() {
  const dynamicBackfillsQuery = useUserPreferences();
  const { data, isLoading } = dynamicBackfillsQuery;
  const dynamicBackfills = data?.settings?.dynamicBackfills;

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences();

  const updateDynamicBackfills = async (dynamicBackfills: boolean) => {
    await updateUserPreferences({ settings: { ...data?.settings, dynamicBackfills } });
  };

  return {
    dynamicBackfills: dynamicBackfills ?? DynamicBackfillsDefault,
    updateDynamicBackfills,
    isLoading,
    dynamicBackfillsQuery,
  };
}
